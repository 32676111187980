<template>
  <div class="col-md-12 col-lg-10 center">
    <CCard class="card-margin">
      <CCardBody>
        <CRow class="align-items-center">
          <CCol> {{ description }} </CCol>
          <CCol md="auto">
            Model: {{ session.model }}<br>
            Usage: IDR {{ usageIDR.toFixed(2) }}
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <template v-for="message in messages">
      <CCard :class="{ 'assistant-card': message.role === 'assistant', 'user-card': message.role === 'user' }"
        class="card-margin">
        <CCardBody>
          <CRow class="align-items-center">
            <CCol md="auto" class="c-row-top">
              <div v-if="message.role === 'assistant'" class="c-avatar">
                <img src="/img/avatars/fav-ico.png" class="c-avatar-img" />
              </div>
              <div v-if="message.role === 'user'" class="c-avatar">
                <img src="/img/avatars/boy.png" class="c-avatar-img" />
              </div>
            </CCol>
            <CCol class="d-flex flex-column">
              <div>
                <span v-html="markdownToHtml(message.content, message.showCaret)" style="white-space: normal"></span>
                <!--<span class="caret" v-if="message.showCaret === true">|</span>-->
              </div>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </template>
    <CCard class="bottom">
      <CCardHeader> {{ guidanceText }}
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol>
            <CAlert :color="alertColor" closeButton :show.sync="alertShow">
              {{ alertMessage }}
            </CAlert>
          </CCol>
        </CRow>
        <CRow class="align-items-center card-margin-one">
          <CCol>
            <CButton color="primary" @click="conceptsPrompt" class="button-margin"> {{ conceptButton }} </CButton>
            <CButton color="primary" @click="practicePrompt" class="button-margin"> {{ practiceButton }} </CButton>
            <CButton color="primary" @click="testPrompt" class="button-margin"> {{ testButton }} </CButton>
            <CButton color="primary" @click="reviewPrompt" class="button-margin"> {{ reviewButton }} </CButton>
          </CCol>
          <CCol md="auto">
            <CButton :color="recording ? 'danger' : 'primary'" @click="toggleRecording"
              class="button-margin no-margin-right">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" role="img"
                class="c-icon c-icon-custom-size icon-margin" height="32">
                <path fill="var(--ci-primary-color, currentColor)"
                  d="M256,328a96.108,96.108,0,0,0,96-96V112a96,96,0,0,0-192,0V232A96.108,96.108,0,0,0,256,328ZM192,112a64,64,0,0,1,128,0V232a64,64,0,0,1-128,0Z"
                  class="ci-primary"></path>
                <path fill="var(--ci-primary-color, currentColor)"
                  d="M400,176v56c0,79.4-64.6,144-144,144S112,311.4,112,232V176H80v56c0,91.653,70.424,167.154,160,175.265V496h32V407.265C361.576,399.154,432,323.653,432,232V176Z"
                  class="ci-primary"></path>
              </svg>
              &nbsp;&nbsp;{{ recording ? 'Stop Recording' : 'Start Recording' }}
            </CButton>
          </CCol>
        </CRow>
        <CRow class="align-items-center">
          <CCol>
            <CTextarea label="" placeholder="Send a message" rows="3" v-model="newMessage" @keyup.enter="sendMessage"
              class="no-bottom">
            </CTextarea>
          </CCol>
          <CCol md="auto">
            <CButton color="primary" @click="sendMessage" class="small-button"> Send </CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import axios from "axios";
import { marked } from "marked";
import Pusher from 'pusher-js'
import { AUTH_LOGOUT } from "../store/actions/auth";
import DOMPurify from 'dompurify';

const MediaRecorder = window.MediaRecorder || window.webkitMediaRecorder || window.mozMediaRecorder || window.msMediaRecorder;

export default {
  name: "SessionDetail",
  data() {
    return {
      alertColor: "success",
      alertShow: false,
      alertMessage: "",
      description: "",
      messageIndex: 0,
      messages: [],
      session: {},
      newMessage: "",
      buttonMap: {
        "English": {
          "concepts": "Concepts",
          "practice": "Practice",
          "test": "Test",
          "review": "Review",
        },
        "Indonesia": {
          "concepts": "Konsep",
          "practice": "Latihan",
          "test": "Ujian",
          "review": "Ulasan",
        }
      },
      guidanceText: "",
      conceptButton: "",
      practiceButton: "",
      testButton: "",
      reviewButton: "",
      pusherClient: null,
      pusherChannelName: "",
      isCaretVisible: false,
      recording: false,
      recordedChunks: [],
      mediaRecorder: null,
      mediaStream: null,
      languageMap: {
        "English": "en",
        "Indonesia": "id",
      },
      usage: 0,
      usageIDR: 0,
    };
  },
  mounted() {
    axios
      .get(config.VUE_APP_ENV_TUTOR_URL + "/sessions/" + this.$route.params.id)
      .then((resp) => {
        this.description =
          "Tutoring session for " +
          resp.data.session.grade +
          " grade. Subject: " +
          resp.data.session.subject +
          ". Chapter: " +
          resp.data.session.chapter;
        this.session = resp.data.session;
        this.messages = resp.data.messages;
        this.usage = resp.data.totalTokenUsage;
        this.usageIDR = (this.usage.total_token_amount / 10000000) * config.VUE_APP_CURRENCY_RATE_USD["IDR"]

        this.conceptButton = this.buttonMap[this.session.language]["concepts"]
        this.practiceButton = this.buttonMap[this.session.language]["practice"]
        this.testButton = this.buttonMap[this.session.language]["test"]
        this.reviewButton = this.buttonMap[this.session.language]["review"]

        if (this.session.language === "English")
          this.guidanceText = "Click on following buttons to start or type any questions related to the subject and chapters. Use the microphone button to record your voice. Click microphone button again to stop recording and send the message."
        else if (this.session.language === "Indonesia")
          this.guidanceText = "Klik tombol berikut untuk memulai atau ketik pertanyaan apa pun yang berkaitan dengan subjek dan bab. Gunakan tombol mikrofon untuk merekam suara Anda. Klik tombol mikrofon lagi untuk menghentikan perekaman dan mengirim pesan."
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          this.$store.dispatch(AUTH_LOGOUT).then(() => {
            this.$router.push("/login");
          });
        } else {
          this.showAlert("warning", "Failed to get session : " + err);
        }
      });


    // Initialize pusher
    this.pusherClient = new Pusher(config.VUE_APP_PUSHER_KEY, { cluster: config.VUE_APP_PUSHER_CLUSTER })
    this.pusherChannelName = `channel-${this.$route.params.id}-${this.$store.state.user.profile.data.user_id}`;
    this.pusherClient.subscribe(this.pusherChannelName)
    this.pusherClient.bind('chat-assistant', data => {
      if (this.messages.length > 0) {
        let lastAssistantMessageIndex = -1;
        for (let i = this.messages.length - 1; i >= 0; i--) {
          if (this.messages[i].role === 'assistant') {
            lastAssistantMessageIndex = i;
            break;
          }
        }

        if (lastAssistantMessageIndex != -1) {
          var prevMessage = this.messages[lastAssistantMessageIndex].content
          if (prevMessage === undefined) {
            prevMessage = ""
          }
          if (data === undefined) {
            data = ""
          }
          this.messages[lastAssistantMessageIndex].content = prevMessage + data
          this.messages[lastAssistantMessageIndex].showCaret = true
        }
      }
    })
    this.pusherClient.bind('chat-user', data => {
      if (this.messages.length > 0) {
        let lastUserMessageIndex = -1;
        for (let i = this.messages.length - 1; i >= 0; i--) {
          if (this.messages[i].role === 'user') {
            lastUserMessageIndex = i;
            break;
          }
        }

        if (lastUserMessageIndex != -1) {
          var prevMessage = this.messages[lastUserMessageIndex].content
          if (prevMessage === undefined) {
            prevMessage = ""
          }
          if (data === undefined) {
            data = ""
          }
          this.messages[lastUserMessageIndex].content = prevMessage + data
          this.messages[lastUserMessageIndex].showCaret = false
        }
      }
    })

  },
  beforeDestroy() {
    this.pusherClient.unsubscribe(this.pusherChannelName)
  },
  methods: {
    conceptsPrompt() {
      if (this.session.language === "English")
        this.newMessage = `I want to learn more about the concepts of subject ${this.session.subject}, chapter ${this.session.chapter} for grade ${this.session.grade}`
      else if (this.session.language === "Indonesia")
        this.newMessage = `Saya ingin belajar lebih banyak tentang konsep subjek ${this.session.subject}, bab ${this.session.chapter} untuk kelas ${this.session.grade}`

      this.sendMessage()
    },
    practicePrompt() {
      if (this.session.language === "English")
        this.newMessage = `I want to practice more about the concepts of subject ${this.session.subject}, chapter ${this.session.chapter} for grade ${this.session.grade}`
      else if (this.session.language === "Indonesia")
        this.newMessage = `Saya ingin berlatih lebih banyak tentang konsep subjek ${this.session.subject}, bab ${this.session.chapter} untuk kelas ${this.session.grade}`

      this.sendMessage()
    },
    testPrompt() {
      if (this.session.language === "English")
        this.newMessage = `I want to test my understanding of the concepts of subject ${this.session.subject}, chapter ${this.session.chapter} for grade ${this.session.grade}`
      else if (this.session.language === "Indonesia")
        this.newMessage = `Saya ingin menguji pemahaman saya tentang konsep subjek ${this.session.subject}, bab ${this.session.chapter} untuk kelas ${this.session.grade}`

      this.sendMessage()
    },
    reviewPrompt() {
      if (this.session.language === "English")
        this.newMessage = `I want to review the concepts of subject ${this.session.subject}, chapter ${this.session.chapter} for grade ${this.session.grade}`
      else if (this.session.language === "Indonesia")
        this.newMessage = `Saya ingin meninjau kembali konsep subjek ${this.session.subject}, bab ${this.session.chapter} untuk kelas ${this.session.grade}`

      this.sendMessage()
    },
    markdownToHtml(content, showCaret) {
      if (showCaret) {
        content += ' <span class="caret">|</span>';
      }
      let rawHtml = marked(content, { breaks: true });
      return rawHtml;
      //return DOMPurify.sanitize(rawHtml);
    },
    showAlert(color, messsage) {
      this.alertShow = true;
      this.alertColor = color;
      this.alertMessage = messsage;
    },
    sendMessage() {
      var messageData = {
        content: this.newMessage,
      };

      this.messages.push({ role: "user", content: this.newMessage, showCaret: false });
      this.messages.push({ role: "assistant", content: "", showCaret: true });

      axios
        .post(
          config.VUE_APP_ENV_TUTOR_URL +
          "/sessions/" +
          this.$route.params.id +
          "/text",
          messageData
        )
        .then((resp) => {
          this.newMessage = "";

          // Fetch data again to repopulate the messages
          axios
            .get(
              config.VUE_APP_ENV_TUTOR_URL +
              "/sessions/" +
              this.$route.params.id
            )
            .then((resp) => {
              this.session = resp.data.session;
              this.messages = resp.data.messages;
              this.usage = resp.data.totalTokenUsage;
              this.usageIDR = (this.usage.total_token_amount / 10000000) * config.VUE_APP_CURRENCY_RATE_USD["IDR"]
            });
        })
        .catch((err) => {
          if (err.message.includes("401")) {
            this.$store.dispatch(AUTH_LOGOUT).then(() => {
              this.$router.push("/login");
            });
          } else {
            if (JSON.stringify(err.response.data).includes("Banned")) {
              this.newMessage = "";

              let lastAssistantMessageIndex = -1;
              for (let i = this.messages.length - 1; i >= 0; i--) {
                if (this.messages[i].role === 'assistant') {
                  lastAssistantMessageIndex = i;
                  break;
                }
              }

              if (lastAssistantMessageIndex != -1) {
                this.messages[lastAssistantMessageIndex].content = "Banned words detected. Please try again. This will not be recorded."
                this.messages[lastUserMessageIndex].showCaret = false
              }

            } else {
              console.log(err.response.data)
              this.showAlert("warning", "Failed to send message: " + err.response.data.message);
            }
          }

        });
    },
    // NEW: Method to start or stop recording
    toggleRecording() {
      if (this.recording) {
        this.stopRecording();
      } else {
        this.startRecording();
      }
    },
    startRecording() {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
          this.mediaStream = stream;
          this.mediaRecorder = new MediaRecorder(stream);

          this.recordedChunks = [];
          this.mediaRecorder.ondataavailable = e => {
            if (e.data.size > 0) {
              this.recordedChunks.push(e.data);
            }
          };

          this.mediaRecorder.onstop = () => {
            const blob = new Blob(this.recordedChunks, { type: 'audio/webm;codecs=opus' });
            this.sendAudio(blob);
          };

          this.mediaRecorder.start();
          this.recording = true;
        })
        .catch(err => {
          console.log('Recording failed: ', err);
        });
    },
    stopRecording() {
      if (this.mediaRecorder) {
        // This will change the state to 'inactive'.
        this.mediaRecorder.stop();

        // Stop all tracks to release the media resources
        if (this.mediaStream) {
          this.mediaStream.getTracks().forEach(track => track.stop());
        }

        this.recording = false;
      }
    },
    sendAudio(blob) {
      // Prepare the text area
      this.messages.push({ role: "user", content: "", showCaret: true });
      this.messages.push({ role: "assistant", content: "", showCaret: true });

      const formData = new FormData();
      formData.append('file', blob);
      formData.append('language', this.languageMap[this.session.language]);

      axios.post(config.VUE_APP_ENV_TUTOR_URL +
        "/sessions/" +
        this.$route.params.id +
        "/audio", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(resp => {
        this.newMessage = "";

        // Fetch data again to repopulate the messages
        axios
          .get(
            config.VUE_APP_ENV_TUTOR_URL +
            "/sessions/" +
            this.$route.params.id
          )
          .then((resp) => {
            this.session = resp.data.session;
            this.messages = resp.data.messages;
          });
      }).catch(error => {
        if (err.message.includes("401")) {
          this.$store.dispatch(AUTH_LOGOUT).then(() => {
            this.$router.push("/login");
          });
        } else {
          this.showAlert("warning", "Failed to send message: " + err);
        }
      });
    },
  }
};
</script>

<style>
.center {
  margin: 0 auto;
}

.card-margin {
  margin-bottom: 0.2rem;
}

.card-margin-one {
  margin-bottom: 1.0rem;
}

.button-margin {
  margin-right: 0.5rem;
}

.bottom {
  position: relative;
  float: center;
  clear: both;
  margin-top: auto;
}

.c-row-top {
  align-self: flex-start;
}

.assistant-card {
  background-color: #f0f8ff;
  /* color for assistant card, change as needed */
}

.user-card {
  background-color: #fafad2;
  /* color for user card, change as needed */
}

.caret {
  font-weight: bold;
  animation: blink 1s step-end infinite;
  display: inline;
}

@keyframes blink {

  from,
  to {
    color: transparent;
  }

  50% {
    color: black;
  }
}

.small-button {
  height: 30px;
  padding: 0 12px;
  margin-bottom: 1rem;
  font-size: 0.875rem;
}

.no-bottom {
  margin-bottom: 0px;
}

.no-margin-right {
  margin-right: 0px;
}
</style>
